import React from "react"
import imgAdobeAfterEffects from "../images/stack-icons/adobe-after-effects.svg"
import imgAdobeCC from "../images/stack-icons/adobe-cc.svg"
import imgAdobeIllustrator from "../images/stack-icons/adobe-illustrator.svg"
import imgAdobeLightroom from "../images/stack-icons/adobe-lightroom.svg"
import imgAdobePhotoshop from "../images/stack-icons/adobe-photoshop.svg"
import imgAdobePremiere from "../images/stack-icons/adobe-premiere.svg"
import imgAndroid from "../images/stack-icons/apps-android.svg"
import imgApple from "../images/stack-icons/apps-apple.svg"
import imgIonic from "../images/stack-icons/apps-ionic.svg"
import imgSwift from "../images/stack-icons/apps-swift.svg"
import imgXamarin from "../images/stack-icons/apps-xamarin.svg"
import imgFirebase from "../images/stack-icons/back-firebase.svg"
import imgJava from "../images/stack-icons/back-java.svg"
import imgPhp from "../images/stack-icons/back-php.svg"
import imgDjango from "../images/stack-icons/back-py-django.svg"
import imgPython from "../images/stack-icons/back-python.svg"
import imgWordpress from "../images/stack-icons/back-wordpress.svg"
import imgSass from "../images/stack-icons/css-sass.svg"
import imgStylus from "../images/stack-icons/css-stylus.svg"
import imgCss from "../images/stack-icons/css.svg"
import imgMysql from "../images/stack-icons/db-mysql.svg"
import imgSqlServer from "../images/stack-icons/db-sql-server.svg"
import imgHtml from "../images/stack-icons/html.svg"
import imgJavascript from "../images/stack-icons/javascript.svg"
import imgAngular from "../images/stack-icons/js-angular.svg"
import imgNode from "../images/stack-icons/js-nodejs.svg"
import imgReact from "../images/stack-icons/js-react.svg"
import imgGatsby from "../images/stack-icons/js-gatsby.svg"
import imgVue from "../images/stack-icons/js-vue.svg"
import imgMsNet from "../images/stack-icons/ms-net.svg"
import imgMsOffice from "../images/stack-icons/ms-office.svg"
import imgBitbucket from "../images/stack-icons/bitbucket.svg"
import imgAxure from "../images/stack-icons/ui-axure.svg"
import imgInvision from "../images/stack-icons/ui-invision.svg"
import imgSketch from "../images/stack-icons/ui-sketch.svg"
import imgZeplin from "../images/stack-icons/ui-zeplin.svg"

const Stack = props => {

  return (
    <>
		<div className="row">
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobeCC} alt="Adobe CC" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobeAfterEffects} alt="Adobe After Effects" />
			</div>			
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobeIllustrator} alt="Adobe Illustrator" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobeLightroom} alt="Adobe Lightroom" />
			</div>			
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobePhotoshop} alt="Adobe Photoshop" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobePremiere} alt="Adobe Premiere" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAxure} alt="Axure" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgInvision} alt="Invision" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgSketch} alt="Sketch" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgZeplin} alt="Zeplin" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgMsOffice} alt="Microsoft Office" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgBitbucket} alt="Bitbucket" />
			</div>								
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgHtml} alt="HTML" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgCss} alt="CSS" />
			</div>					
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgSass} alt="Sass" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgStylus} alt="Stylus" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgMysql} alt="MySQL" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgSqlServer} alt="SQL Server" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgJavascript} alt="JavaScript" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAngular} alt="Angular" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgNode} alt="Node" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgReact} alt="React" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgGatsby} alt="Gatsby" />
			</div>				
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgVue} alt="Vue" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgFirebase} alt="Firebase" />
			</div>			
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgMsNet} alt=".NET" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgJava} alt="Java" />
			</div>				
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgPhp} alt="PHP" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgDjango} alt="Django" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgPython} alt="Python" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgWordpress} alt="WordPress" />
			</div>		
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAndroid} alt="Android" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgApple} alt="Apple" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgIonic} alt="Ionic" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgSwift} alt="Swift" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgXamarin} alt="Xamarin" />
			</div>																																																																																											
		</div>
    </>
  )
}


export default Stack